import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 290.626 290.626" {...props}>
<path  d="M79.689,131.25c-43.941,0-79.688,35.747-79.688,79.688s35.747,79.688,79.687,79.688
				s79.687-35.747,79.687-79.688S123.629,131.25,79.689,131.25z M79.689,281.25c-38.77,0-70.312-31.542-70.312-70.313
				s31.542-70.313,70.312-70.313s70.312,31.543,70.312,70.314S118.459,281.25,79.689,281.25z"/>
			<path  d="M86.72,164.063h-2.344V150h-9.375v14.063h-2.344c-14.212,0-25.781,11.569-25.781,25.781
				s11.569,25.781,25.781,25.781h14.062c9.047,0,16.406,7.359,16.406,16.406s-7.359,16.406-16.406,16.406H72.657
				c-9.047,0-16.406-7.359-16.406-16.406V225h-9.375v7.031c0,14.213,11.569,25.781,25.781,25.781h2.344v14.063h9.375v-14.063h2.344
				c14.212,0,25.781-11.569,25.781-25.781S100.932,206.25,86.72,206.25H72.657c-9.047,0-16.406-7.359-16.406-16.406
				s7.359-16.406,16.406-16.406h14.062c9.047,0,16.406,7.359,16.406,16.406v7.031h9.375v-7.031
				C112.501,175.631,100.932,164.063,86.72,164.063z"/>
			<path  d="M290.625,28.125h-26.283c4.575-2.63,7.533-7.495,7.533-13.031C271.875,6.769,265.107,0,256.781,0
				c-6.441,0-12.764,2.217-17.794,6.244l-13.987,11.189L211.012,6.239C205.979,2.217,199.66,0,193.219,0
				c-8.325,0-15.094,6.769-15.094,15.094c0,5.541,2.958,10.402,7.533,13.031h-26.283v37.5h14.062v79.688h37.5h28.125h37.5V65.625
				h14.062L290.625,28.125L290.625,28.125z M229.688,37.5v18.75h-9.375V37.5h4.172l0.516,0.112l0.516-0.112H229.688z
				 M244.848,13.561c3.375-2.7,7.612-4.186,11.934-4.186c3.155,0,5.719,2.564,5.719,5.719c0,2.7-1.842,4.992-4.477,5.578
				l-30.548,6.787L244.848,13.561z M193.219,9.375c4.322,0,8.559,1.486,11.934,4.186l17.372,13.898l-30.548-6.787
				c-2.634-0.586-4.477-2.883-4.477-5.578C187.501,11.939,190.064,9.375,193.219,9.375z M168.751,56.25V37.5h42.187v18.75h-37.5
				H168.751z M182.813,135.938V65.625h28.125v70.313H182.813z M220.313,135.938V65.625h9.375v70.313H220.313z M267.188,135.938
				h-28.125V65.625h28.125V135.938z M281.25,56.25h-4.687h-37.5V37.5h42.187V56.25z"/>
			<path  d="M122.893,44.658l-2.034-9.15c-42.436,9.412-75.937,42.914-85.35,85.35l9.155,2.03
				C53.289,83.995,83.996,53.287,122.893,44.658z"/>
			<path  d="M167.734,245.962l2.034,9.155c42.436-9.417,75.933-42.914,85.35-85.35l-9.155-2.034
				C237.338,206.63,206.631,237.337,167.734,245.962z"/>
  </Svg>
);

export default Icon;
