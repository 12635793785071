import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 490.738 490.738" {...props}>
	<path d="M394.557,0H157.682c-24.355,0-44.158,19.813-44.158,44.158v17.385H96.211c-24.355,0-44.174,19.813-44.174,44.16V446.58
		c0,24.346,19.818,44.158,44.174,44.158h236.84c24.357,0,44.18-19.813,44.18-44.158v-17.352h17.326
		c24.336,0,44.145-19.813,44.145-44.16V44.158C438.701,19.813,418.893,0,394.557,0z M344.066,446.58
		c0,6.063-4.938,10.994-11.016,10.994H96.211c-6.07,0-11.014-4.932-11.014-10.994V105.703c0-6.064,4.943-10.996,11.014-10.996
		h236.84c6.078,0,11.016,4.932,11.016,10.996V446.58z M405.537,385.068c0,6.064-4.922,10.998-10.98,10.998H377.23V105.703
		c0-24.348-19.822-44.16-44.18-44.16H146.688V44.158c0-6.063,4.938-10.994,10.994-10.994h236.875c6.059,0,10.98,4.932,10.98,10.994
		V385.068z"/>
	<path d="M230.842,255.975c-25.973-9.777-36.695-16.199-36.695-26.273c0-8.559,6.432-17.115,26.285-17.115
		c11.154,0,20.287,1.807,27.592,4.023c4.375,1.328,9.104,0.801,13.082-1.439c3.984-2.242,6.885-6.008,8.02-10.436l0.289-1.119
		c2.367-9.262-3.141-18.713-12.389-21.189c-7.674-2.057-16.855-3.563-28.031-4.064v-11.918c0-8.258-6.701-14.961-14.959-14.961
		c-8.279,0-14.965,6.695-14.965,14.961V180.5c-32.711,6.42-51.654,27.504-51.654,54.4c0,29.633,22.314,44.904,55.006,55.9
		c22.623,7.643,32.389,14.978,32.389,26.588c0,12.227-11.918,18.947-29.33,18.947c-12.143,0-23.605-2.398-33.598-5.863
		c-4.518-1.563-9.473-1.158-13.686,1.102c-4.195,2.26-7.25,6.18-8.449,10.793l-0.295,1.174c-2.412,9.369,2.918,18.979,12.113,21.928
		c10.496,3.359,23.094,5.738,35.951,6.322v14.039c0,8.275,6.701,14.978,14.98,14.978c8.273,0,14.975-6.703,14.975-14.978v-16.186
		c35.141-6.121,54.379-29.334,54.379-56.521C281.852,285.617,267.182,268.811,230.842,255.975z"/></Svg>
);

export default Icon;
